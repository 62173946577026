<!-- 订单详情 -->
<template>
  <div class="walletBeans">
    <div class="walletBeansMain">
    </div>
    <div class="walletBeansBottom">
      <div class="walletBeansBottom-top">
        <div class="walletBeansBottom-top-text">{{walletBalanceObject.amount}}(元)</div>
        <div class="walletBeansBottom-top-beans">余额:{{walletBalanceObject.balance}}元</div>
      </div>
      <div class="walletBeansBottom-bottom">
        <div class="walletBeansBottom-bottom-box">
          <div class="walletBeansBottom-bottom-main">
          <span>订单类型</span>
          <span>分佣</span>
        </div>
        </div>
        <div class="walletBeansBottom-bottom-box">
          <div class="walletBeansBottom-bottom-main">
          <span>下单用户</span>
            <div class="ImageRight">
              <van-image
              style="margin-right: 10px;"
               width="34px"
                height="34px"
                fit="cover"
                round
                :src="walletBalanceObject.headImg"></van-image>
                <span >
            {{ walletBalanceObject.userNickname || '--'}}
          </span>
            </div>
        </div>
        </div>
        <div class="walletBeansBottom-bottom-box">
          <div class="walletBeansBottom-bottom-main">
          <span>支付金额</span>

          <span>{{walletBalanceObject.paidPrice || '' }}</span>
        </div>
        </div>
        <div class="walletBeansBottom-bottom-box">
          <div class="walletBeansBottom-bottom-main">
          <span>分成比例</span>
          <span>{{ walletBalanceObject.commissionRate || '' }}</span>
        </div>
        </div>
        <div class="walletBeansBottom-bottom-box">
          <div class="walletBeansBottom-bottom-main">
          <span>订单号</span>
          <span>{{ walletBalanceObject.orderCode || '' }}</span>
        </div>
        </div>
      </div>
      <div class="walletBeansBottom-timeText">
        订单时间： {{ $route.query.changeTime|| '' }}
      </div>
    </div>
  </div>
</template>

<script>
import {getWalletBalanceDetail} from "@/api/myWalletBeans/myWalletBeans";

export default {
  name: "myWalletBeans",
  computed:{
    BIZ_USERID(){
      return this.$route.query.userToken || ''
    }
  },
  data() {
    return {
      ImageSrc: { image: require("../../assets/auditingState/jindutiao-qiandai.png") },
      walletBalanceObject:{
      },
    };
  },
  created() {
    this.getWalletBalanceDetail()
  },
  mounted() {
  },
  methods: {
    // 详情接口获取明细
    getWalletBalanceDetail(){
      let {bizOrderNo,chgAmount,curAmount} = this.$route.query.objectDetail || {}
      getWalletBalanceDetail(this.$route.query.token,bizOrderNo,chgAmount,curAmount).then(res=>{
        if(res.data.code == '200'){
          this.walletBalanceObject = res.data.data
        }
      }).catch(()=>{
        return false
      })
    }
  },
};
</script>

<style scoped lang="scss">
.walletBeans{
  position: relative;
  height: 100vh;
  background-color: #F4F4F4;
  box-sizing: border-box;
}
.walletBeansMain{
  height: 200px;
  background-image: linear-gradient(to bottom, #FF924B90 , #FF924B50);
}
.walletBeansBottom{
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - 20px);
  height: 400px;
  float: left;
  border-radius: 8px;
  background-color: #ffffff;
  .walletBeansBottom-top{
    padding: 20px 0;
    .walletBeansBottom-top-text{
      font-size: 36px;
      font-weight: 500;
    }
    .walletBeansBottom-top-beans{
      margin-top: 4px;
      font-size: 16px;
      color: #666666;
    }
  }
  .walletBeansBottom-bottom{
    .walletBeansBottom-bottom-box{
      padding: 0 20px;
    }
    .walletBeansBottom-bottom-main{
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #e2e2e2;
      color: #666666;
      display: flex;
      justify-content: space-between;
      align-content: center;
      .ImageRight{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      span:first-child{
        width: 20%;
        text-align: right;
      }
    }
  }
}
.walletBeansBottom-timeText{
  height: 40px;
  line-height: 40px;
  color: #999999;
  font-size: 14px;
}


</style>
